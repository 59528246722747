<template>
    <Modal v-model="showForm" title="编辑题目" :mask-closable="false">
        <Form ref="editForm" :model="editForm" :rules="editFormRule" label-colon :label-width="80">
            <FormItem label="一级标签" prop="primaryClassification">
                <Select v-model="editForm.primaryClassification" filterable clearable @on-change="changePrimaryClassification" @on-clear="clearPrimaryClassification">
                    <Option v-for="item in primaryClassificationArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
            </FormItem>
            <FormItem label="二级标签" prop="secondaryClassification">
                <Select v-model="editForm.secondaryClassification" filterable clearable>
                    <Option v-for="item in secondaryClassificationArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
            </FormItem>
            <FormItem label="题目名称" prop="name">
                <Input type="text" :maxlength="200" v-model="editForm.name" placeholder="请输入题目名称"/>
            </FormItem>
            <FormItem label="答案解析" prop="answer">
                <quill-editor v-model="editForm.answer" :options="editorOption"></quill-editor>
            </FormItem>
            <FormItem label="难度等级" prop="rate">
                <Rate v-model="editForm.rate"></Rate>
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>
import {quillEditor} from 'vue-quill-editor';
// 富文本编辑器外部引用样式  三种样式三选一引入即可
import 'quill/dist/quill.snow.css';
import {checkEditorContent} from "../../../common/publicFuns";
import {
    reqAddQuestion, reqEditQuestion,
    reqGetPrimaryClassificationArray,
    reqGetSecondList,
    reqGetWcQuestionById
} from "../../../api/admin-api";

export default {
    name: "editWcQuestion",
    components: {
        quillEditor
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        id: ''
    },
    data() {
        return {
            editorOption: { //  富文本编辑器配置
                modules:{
                    clipboard: {
                        // 粘贴版，处理粘贴时候带图片
                        matchers: [['img', this.handleCustomMatcher]],
                    },
                    toolbar:[
                        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                        ['blockquote', 'code-block'],
                        [{ 'header': 1 }, { 'header': 2 }],
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                        [{ 'indent': '-1'}, { 'indent': '+1' }],
                        ['clean']
                    ]
                },
                theme: 'snow',
                placeholder: '请输入'
            },
            loading: false,
            showForm: this.value,
            primaryClassificationArray: [],
            secondaryClassificationArray: [],
            editForm: {
                primaryClassification: null,
                secondaryClassification: null,
                name: '',
                answer: '',
                rate: null,
            },
            editFormRule: {
                primaryClassification: [{required: true, message: '请选择一级分类', trigger: 'change'}],
                secondaryClassification: [{required: true, message: '请选择二级分类', trigger: 'change'}],
                name: [{required: true, message: '请输入题目名称', trigger: 'blur'}],
                answer: [{required: true, trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if (!value || value.length === 0) {
                            return callback(new Error('请输入答案解析'));
                        }
                        if (value && !checkEditorContent(value, 20000)) {
                            return callback(new Error('答案解析不能超过20000字符'));
                        }
                        return callback();
                    }
                    }
                ],
                rate: [{required: true, message: '请选择评分' , trigger: "change", type: "number",}],
            },
        }
    },
    methods: {
        getQuestionById() {
            reqGetWcQuestionById({id:this.id}).then((res)=>{
                if (res.data.code === 1){
                    this.editForm = res.data.data
                    this.getPrimaryClassificationArray()
                    this.changePrimaryClassification(res.data.data.primaryClassification)
                }else {
                    this.editForm = {}
                }
            }).catch(()=>{
                this.editForm = {}
            })
        },
        getPrimaryClassificationArray(){
            reqGetPrimaryClassificationArray().then((res)=>{
                if (res.data.code === 1){
                    this.primaryClassificationArray = res.data.data
                } else {
                    this.primaryClassificationArray = [];
                }
            }).catch(()=>{
                this.primaryClassificationArray = [];
            })
        },
        changePrimaryClassification(val) {
            if (val !== undefined){
                reqGetSecondList({id: val , keywords: ''}).then((res)=>{
                    if (res.data.code === 1){
                        this.secondaryClassificationArray = res.data.data;
                    }else {
                        this.secondaryClassificationArray = []
                    }
                }).catch(()=>{
                    this.secondaryClassificationArray = []
                })
            }
        },
        clearPrimaryClassification() {
            this.editForm.secondaryClassification = null
            this.secondaryClassificationArray = []
        },
        handleSubmit() {
            this.loading = true;
            this.$refs['editForm'].validate((valid) => {
                if (!valid) {
                    this.loading = false;
                    return false;
                }
                reqEditQuestion(this.editForm).then((res)=>{
                    this.loading = false;
                    if (res.data.code === 1){
                        this.$Message.success('添加成功');
                        this.$emit('getQuestion');
                        this.closeModal(false);
                    }
                }).catch(()=>{
                    this.loading = false;
                    this.$Message.error('添加失败');
                })
            });
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['editForm'].resetFields();
                this.getQuestionById()
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    },
    mounted() {
        let token = sessionStorage.getItem('Authorization')
        this.header = {Authorization: token}
    }
}
</script>
