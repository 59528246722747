<template>
    <Modal v-model="showForm" title="查看题目" :mask-closable="false">
        <Form class="plain-form" ref="viewForm" :model="viewForm" :rules="viewFormRule" label-colon :label-width="90">
            <FormItem label="一级标签">{{ viewForm.primaryClassificationName }}</FormItem>
            <FormItem label="二级标签">{{ viewForm.secondaryClassificationName }}</FormItem>
            <FormItem label="题目名称">{{ viewForm.name }}</FormItem>
            <FormItem label="答案解析"><div class="multiline-content" v-html="viewForm.answer"></div></FormItem>
            <FormItem label="难度等级"> <Rate v-model="viewForm.rate" :disabled="true"></Rate></FormItem>
            <FormItem label="查看次数">{{ viewForm.viewCounts }}</FormItem>
            <FormItem label="收藏次数">{{ viewForm.collectCounts }}</FormItem>
            <FormItem label="更新人">{{ viewForm.updatedByName }}</FormItem>
            <FormItem label="更新时间">{{ viewForm.updatedTime }}</FormItem>
        </Form>
        <div slot="footer">
            <Button type="primary" @click="closeModal(false)">确定</Button>
        </div>
    </Modal>
</template>
<script>
import {reqGetWcQuestionById} from "../../../api/admin-api";

export default {
    name: "viewWcQuestion",
    components: {},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        id: '',
    },
    data() {
        return {
            loading: false,
            showForm: this.value,
            viewForm: {},
            viewFormRule: {},
        }
    },
    methods: {
        getQuestionById() {
            reqGetWcQuestionById({id:this.id}).then((res)=>{
                if (res.data.code === 1){
                    this.viewForm = res.data.data
                    console.log(this.viewForm)
                }else {
                    this.viewForm = {}
                }
            }).catch(()=>{
                this.viewForm = {}
            })
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['viewForm'].resetFields();
                this.getQuestionById()
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    },
}
</script>
