<template>
    <div class="main-content">
        <div class="breadcrumb">
            <Breadcrumb>
                <BreadcrumbItem>小程序管理</BreadcrumbItem>
                <BreadcrumbItem>题库管理</BreadcrumbItem>
            </Breadcrumb>
        </div>
        <div class="filter">
            <Form label-colon inline>
                <FormItem class="ivu-form-item-xl">
                    <Input v-model="filter.keywords" placeholder="题目名称" clearable/>
                </FormItem>
                <FormItem>
                    <Select v-model="filter.rate" clearable placeholder="请选择难度">
                        <Option v-for="item in rateList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="doSearching">搜索</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="resetting">重置</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" icon="md-add" @click="handleAdd" v-if="permissionButton.add">新增</Button>
                </FormItem>
            </Form>
        </div>
        <div class="table">
            <div class="table-list">
                <Table stripe :columns="columns" :data="dataList" :loading="loading">
                    <template slot-scope="{ row, index }" slot="action">
                        <Dropdown transfer>
                            <a href="javascript:void(0)">操作<Icon type="ios-arrow-down"></Icon></a>
                            <DropdownMenu class="table-dropdown" slot="list">
                                <DropdownItem v-if="permissionButton.view" @click.native="handleView(row, index)">详情</DropdownItem>
                                <DropdownItem v-if="permissionButton.edit" @click.native="handleEdit(row, index)">编辑</DropdownItem>
                                <DropdownItem class="table-delete" v-if="permissionButton.del" @click.native="handleDel(row, index)">删除</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </template>
                </Table>
            </div>
            <div class="table-footer">
                <Row>
                    <Col class="total" span="6">共 {{ total }} 条记录 第 {{ pageNum }}/{{ pages }} 页</Col>
                    <Col class="pagenation" span="18">
                        <Page :page-size="pageSize" :total="total" :current="pageNum"
                              :page-size-opts="[15, 20, 50, 100]"
                              @on-change="changePage" @on-page-size-change="changPageSize" show-sizer show-elevator/>
                    </Col>
                </Row>
            </div>
            <add-question v-model="showAddModal" v-on:getQuestion="getQuestion"></add-question>
            <edit-question v-model="showEditModal" :id="id" v-on:getQuestion="getQuestion"></edit-question>
            <view-question v-model="showViewModal" :id="id"></view-question>
        </div>
    </div>
</template>

<script>
import permission from '@/common/permission'
import addQuestion from "./add.vue"
import editQuestion from "./edit.vue"
import viewQuestion from "./view.vue"
import {reqDelQuestion, reqGetWcQuestion} from "../../../api/admin-api";

export default {
    name: 'wcQuestionList',
    components: {
        addQuestion,
        editQuestion,
        viewQuestion
    },
    data() {
        return {
            id: '',
            filter: {
                keywords: '',
                rate: null,
            },
            loading: false,
            dataList: [],
            showAddModal: false,
            showEditModal: false,
            showViewModal: false,
            rateList: [
                {id: 1 , name: '1'},
                {id: 2 , name: '2'},
                {id: 3 , name: '3'},
                {id: 4 , name: '4'},
                {id: 5 , name: '5'},
            ],
            permissionButton: {
                add: false,
                edit: false,
                view: false,
                del: false
            },
            total: 0,
            pages: 1,
            pageSize: 20,
            pageNum: 1,
            columns: [
                {
                    title: '一级分类',
                    key: 'primaryClassificationName',
                    tooltip: 'true',
                    width: 200,
                },
                {
                    title: '二级分类',
                    key: 'secondaryClassificationName',
                    tooltip: 'true',
                    width: 200,
                },
                {
                    title: '题目名称',
                    key: 'name',
                    tooltip: 'true',
                    minWidth: 400,
                },
                {
                    title: '难度等级',
                    key: 'rate',
                    align: 'center',
                    width: 120,
                },
                {
                    title: '查看次数',
                    key: 'viewCounts',
                    align: 'center',
                    width: 100,
                },
                {
                    title: '收藏次数',
                    key: 'collectCounts',
                    align: 'center',
                    width: 100,
                },
                {
                    title: '更新人',
                    key: 'updatedByName',
                    width: 120,
                },
                {
                    title: '更新时间',
                    key: 'updatedTime',
                    width: 180,
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 120,
                    align: 'center',
                    fixed: 'right',
                },
            ],
        }
    },
    methods: {
        getQuestion() {
            this.loading = true;
            this.dataList = [];
            let para = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                keywords: this.filter.keywords,
                rate: this.filter.rate
            };
            reqGetWcQuestion(para).then((res)=>{
                this.loading = false;
                this.pageNum = res.data.data.current
                this.pages = res.data.data.pages
                this.total = res.data.data.total
                this.dataList = res.data.data.records;
            }).catch(()=>{
                this.loading = false;
                this.dataList = [];
            })
        },
        resetting() {
            this.filter.keywords = ''
            this.filter.rate = null
            this.getQuestion();
        },
        handleAdd() {
            this.showAddModal = true;
        },
        handleEdit(row, index) {
            this.id = row.id
            this.showEditModal = true;
        },
        handleView(row, index) {
            this.id = row.id
            this.showViewModal = true;
        },
        handleDel(row, index) {
            this.$Modal.confirm({
                title: '删除题目',
                content: '确定删除题目：'+row.name+'？此操作不可逆。',
                width: '320',
                onOk: () => {
                    reqDelQuestion({id: row.id}).then((res)=>{
                        if(res.data.code === 1) {
                            this.getQuestion();
                            this.$Message.success('删除成功');
                        }else {
                            this.$Message.error('删除失败');
                        }
                    }).catch(()=>{
                        this.$Message.error('删除失败');
                    })
                },
            })
        },
        //分页点击，切换页面
        changePage(val) {
            this.pageNum = val;
            this.getQuestion();
        },
        //每页显示的数据条数
        changPageSize(numOfRows) {
            //实时获取当前需要显示的条数
            this.pageSize = numOfRows;
            this.getQuestion();
        },
        doSearching() {
            this.pageNum = 1;
            this.getQuestion();
        }
    },
    mounted() {
        let permissionId = this.$route.query.permissionId
        permission.getPermissionButton(permissionId, this.permissionButton)
        this.getQuestion();
    }
}
</script>
